<template>
  <div id="bill">
    <div class="billing_details" v-for="list in order" :key="list.name">
      <div class="top van-hairline--bottom">
        <van-image :round="true" width="50" height="50" />
        <p>{{ list.name }}</p>
        <span>-{{ list.allPrice | Price }}</span>
      </div>
      <van-row
        class="goods"
        :class="i === list.goods.length - 1 ? 'dashed bottom24' : ''"
        v-for="(goods, i) in list.goods"
        :key="i"
      >
        <van-col span="10">
          <p v-if="i < 1">商品</p>
          <p>{{ goods.name }}</p>
        </van-col>
        <van-col span="3" style="text-align: center" offset="1">
          <p v-if="i < 1">数量</p>
          <p>{{ goods.num }}</p>
        </van-col>
        <van-col span="3" style="text-align: center" offset="1">
          <p v-if="i < 1">规格</p>
          <p>{{ goods.g }}</p>
        </van-col>
        <van-col span="5" style="text-align: end" offset="1">
          <p v-if="i < 1">金额</p>
          <p>￥{{ (goods.price * goods.num) | Price }}</p>
        </van-col>
      </van-row>
      <div class="details dashed">
        <van-row>
          <van-col span="4">当前状态</van-col>
          <van-col span="17" offset="3">支付成功</van-col>
        </van-row>
        <van-row>
          <van-col span="4">生产厂家</van-col>
          <van-col span="17" offset="3"
            ><a href="#">{{ list.name }}</a></van-col
          >
        </van-row>
        <van-row>
          <van-col span="4">支付时间</van-col>
          <van-col span="17" offset="3">2022-04-18 15:00</van-col>
        </van-row>
        <van-row>
          <van-col span="4">支付方式</van-col>
          <van-col span="17" offset="3">徽商银行</van-col>
        </van-row>
        <van-row>
          <van-col span="4">订单号码</van-col>
          <van-col span="17" offset="3">4200000837202171126383174318</van-col>
        </van-row>
        <van-row>
          <van-col span="4">商户单号</van-col>
          <van-col span="17" offset="3">可在支持的商户扫码退款</van-col>
        </van-row>
        <div style="">
          <img src="@/assets/images/code.png" alt="" />
        </div>
        <p>48956212354798622021320141</p>
      </div>
      <div class="details">
        <van-row>
          <van-col span="4">电子保单</van-col>
          <van-col span="17" offset="3"
            ><a href="#/InsurancePolicy"
              >1587588455464554（太平洋保险）</a
            ></van-col
          >
        </van-row>
        <van-row>
          <van-col span="4">物流轨迹</van-col>
          <van-col span="17" offset="3"
            ><a href="#/LogisticsTrajectory">SF8098483083120</a></van-col
          >
        </van-row>
        <van-row>
          <van-col span="4">检测信息</van-col>
          <van-col span="17" offset="3"
            ><a href="#/inspection-information">AFSQB090579001C</a></van-col
          >
        </van-row>
        <van-row>
          <van-col span="5">原产地<br />(地理标志)</van-col>
          <van-col span="17" offset="2"><a href="#">福建武夷山</a></van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bill",

  data() {
    return {
      order: "",
    };
  },
  filters: {
    Price(num) {
      if (num === null) {
        return "-";
      } else if (String(Number(num)).indexOf(".")) {
        // return num;
        return num.toFixed(2);
      } else {
        return num;
      }
    },
  },
  created() {
    this.order = JSON.parse(sessionStorage.getItem("order"));
    // this.order.allPrice =
    for (let i = 0; i < this.order.length; i++) {
      this.order[i].allPrice = 0;
      this.order[i].goods.forEach((g) => {
        this.order[i].allPrice += Number(g.price) * g.num;
      });
    }

    console.log(this.order);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
#bill {
  padding: 0 12px;
  position: relative;
  top: 0;
  bottom: 0;
  background-color: #f5f5f5;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid transparent;
  .billing_details {
    margin: 50px 0;
    background-color: #fff;
    border-radius: 12px;
    padding: 0 12px;
    .top {
      position: relative;
      top: -25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 30px;
      p {
        font-size: 16px;
        color: #333333;
        line-height: 16px;
        margin: 15px 0 10px 0;
      }
      span {
        font-size: 30px;
        font-family: HarmonyOS Sans SC-Medium, HarmonyOS Sans SC;
        font-weight: 500;
        color: #fc2d14;
        line-height: 40px;
      }
    }
    .goods {
      font-size: 13px;
      color: #333333;
      padding-bottom: 12px;
      .van-col {
        p:nth-child(1) {
          margin-bottom: 12px;
          line-height: 13px;
        }
        p:nth-child(2) {
          line-height: 16px;
        }
      }
    }
    .bottom24 {
      padding-bottom: 24px;
    }
    .details {
      padding: 24px 0;
      font-size: 13px;
      line-height: 17px;
      color: #333333;
      .van-row {
        margin-bottom: 10px;
        .van-col:nth-child(1) {
          color: #666;
        }
        a {
          color: #438afe;
          text-decoration: underline;
        }
      }
      img {
        width: 228px;
        height: 52px;
        display: block;
        margin: 0 auto;
      }
      p {
        font-size: 12px;
        text-align: center;
        margin-top: 8px;
      }
    }
  }

  .van-hairline--bottom::after {
    border-color: #ddd;
  }
  //虚线
  .dashed {
    position: relative;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    .dashed::before {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border-top: 1px dashed #ddd;
      color: #ddd;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
}
</style>